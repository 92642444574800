import styled from "styled-components";
import { Container } from "../../Styled";

const Styles = {
  LandingWrapper: styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
  `,
  LandingContainer: styled(Container)`
    position: relative;
    height: 100vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 40;
  `,
  Header: styled.h1`
    font-size: 20px;
    margin: 0;
    padding: 10px 0 0 0;
    font-weight: 500;
    @media (max-width: 500px) {
      font-size: 20px;
    }
  `,
  ImageWrapper: styled.div`
    top: 120px;
    position: absolute;
    width: 100%;
    height: calc(100vh - 120px);
    @media (max-width: 890px) {
      top: 60px;
      height: calc(100vh - 60px);
    }
  `,
  ImageFog: styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 63, 57, 0.8);
    z-index: 15;
  `,
};

export default Styles;
