import styled from "styled-components";

const Styles = {
  ImageWrapper: styled.div`
    width: 100%;
    height: 100%;

    @media (max-width: 810px) {
      height: 400px;
    }
  `,
  InfoWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 100px 50px 100px;

    & > p,
    h2,
    h3,
    li {
      color: ${(props) => props.theme.color.green};
    }

    & > p {
      margin: 10px 0 10px 0;
      font-size: 18px;
    }

    @media (max-width: 768px) {
      padding: 100px 20px 100px 20px;
    }
  `,
  InfoText: styled.p`
    margin: 10px 0 10px 0;
    font-size: 18px;
    text-align: center;
    color: ${(props) => props.theme.color.green};
  `,
};

export default Styles;
