import React from "react";
import { SVGIcon } from "../../Styled";
import Styles from "./styled";
import Icons from "../../images/icons";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";

const {
  LandingWrapper,
  LandingContainer,
  Header,
  ImageWrapper,
  ImageFog,
} = Styles;
const { feather } = Icons;

const Landing = () => {
  const intl = useIntl();
  const { allFile } = useStaticQuery(graphql`
    query landingQuery {
      allFile(filter: { name: { eq: "info-5" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <LandingWrapper>
      <ImageWrapper>
        <ImageFog />
        <GatsbyImage
          style={{ width: "100%", height: "100%", zIndex: 10 }}
          imgStyle={{ zIndex: 10 }}
          image={allFile.edges[0].node.childImageSharp.gatsbyImageData}
          alt="sworn translator landing image"
        />
      </ImageWrapper>
      <LandingContainer>
        <SVGIcon src={feather} width="64px" height="64px" alt="logo" />
        <Header>{intl.formatMessage({ id: "title1" })}</Header>
        <Header>{intl.formatMessage({ id: "title2" })}</Header>
      </LandingContainer>
    </LandingWrapper>
  );
};

export default Landing;
