import styled from "styled-components";

const Styles = {
  ContactWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 50px 16px 50px 16px;

    & > .address {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
  H3Title: styled.h3`
    color: ${(props) => props.theme.color.green};
    font-size: 18px;
    margin: 0 0 25px 0;
    text-align: center;
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  `,
  Text: styled.p`
    color: ${(props) => props.theme.color.green};
    font-style: normal;
    font-size: 18px;
    margin-left: 15px;
    @media (max-width: 400px) {
      font-size: 16px;
    }
  `,
};

export default Styles;
