import styled, { css } from "styled-components";
import { Container } from "../../Styled";

interface IDoubleWrapper {
  reverted?: boolean;
}

const Styles = {
  DoubleWrapper: styled.div<IDoubleWrapper>`
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: dense;

    ${({ reverted }) =>
      reverted === true
        ? css`
            & > div:nth-child(1) {
              grid-column: 2;
              grid-row: 1; /* NEW */
            }
          `
        : css``}

    @media (max-width: 810px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;

      & > div:nth-child(1) {
        grid-column: unset;
        grid-row: unset; /* NEW */
      }
    }
  `,
  QuestionsWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(90deg, #253e38, #496860 100%);
    width: 100%;
    height: 320px;
  `,
  QuestionText: styled.p`
    margin: 15px;
    font-size: 30px;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 22px;
    }
  `,
};

export default Styles;
