import React from "react";
import Layout from "../components/Layout";
import Landing from "../components/Landing";
import Services from "../components/Services";
import Info from "../components/Info";
import Contact from "../components/Contact";
import { useStaticQuery, graphql } from "gatsby";
import PeopleComponent from "../components/Content/People";
import ServicesComponent from "../components/Content/Services";
import SalaryComponent from "../components/Content/Salary";
import { Element } from "react-scroll";

const IndexPage = () => {
  const { allFile } = useStaticQuery(graphql`
    query infoQuery {
      allFile(filter: { name: { in: ["info", "info-1", "info-3"] } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Landing />
      {/*
      <Services />
*/}
      <Element name="services">
        <Info Content={PeopleComponent} reverted={true} />
        <Info Content={ServicesComponent} />
        <Info Content={SalaryComponent} reverted={true} question={true} />
      </Element>

      <Contact />
    </Layout>
  );
};

export default IndexPage;
