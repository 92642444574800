import React from "react";
import Styles from "./styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";

const { ImageWrapper, InfoWrapper } = Styles;

const People = () => {
  const intl = useIntl();

  const { allFile } = useStaticQuery(graphql`
    query peopleQuery {
      allFile(filter: { name: { eq: "info-2" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <ImageWrapper>
        <GatsbyImage
          style={{
            width: "100%",
            maxHeight: "100%",
            minHeight: "100%",
          }}
          objectFit={"cover"}
          image={allFile.edges[0].node.childImageSharp.gatsbyImageData}
          alt={intl.formatMessage({ id: "addInfo1" })}
        />
      </ImageWrapper>
      <InfoWrapper>
        <h2>{intl.formatMessage({ id: "peopleTitle1" })}</h2>
        <p>{intl.formatMessage({ id: "peopleText1" })}</p>
        <h2>{intl.formatMessage({ id: "peopleSubtitle1" })}</h2>
        <p>{intl.formatMessage({ id: "peopleText2" })}</p>
      </InfoWrapper>
    </>
  );
};

export default People;
