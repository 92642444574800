import React from "react";
import Styles from "./styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import { SVGIcon } from "../../Styled";
import Icons from "../../images/icons";
import { Element } from "react-scroll";

const { ContactWrapper, H3Title, Row, Text } = Styles;
const { greenPhone, greenEnvelope } = Icons;

const Contact = () => {
  const intl = useIntl();

  return (
    <Element name="contact">
      <ContactWrapper>
        <H3Title>Kontakt</H3Title>
        <address>
          <Row>
            <Text> {intl.formatMessage({ id: "title2" })}</Text>
          </Row>
          <Row>
            <Text>ul. Wędkarzy 16, 51-050 Wrocław</Text>
          </Row>
          <Row>
            <Text>
              {intl.formatMessage({ id: "address1" })}: ul. Bolesławiecka 1/29a,
              53-614 Wrocław
            </Text>
          </Row>

          <Row>
            <Text>
              {intl.formatMessage({ id: "address2" })} Marta Prokop- Majchrzak
            </Text>
          </Row>

          <Row>
            <SVGIcon
              src={greenEnvelope}
              width="44px"
              height="44px"
              alt="email"
            />
            <Text>radca@kancelaria-prokop-majchrzak.com</Text>
          </Row>
          <Row>
            <SVGIcon src={greenPhone} width="44px" height="44px" alt="phone" />
            <Text>
              {intl.formatMessage({ id: "address3" })} +48 509-480-983
            </Text>
          </Row>

          <Row>
            <Text>
              {intl.formatMessage({ id: "address4" })} Bartosz Majchrzak
            </Text>
          </Row>
          <Row>
            <SVGIcon
              src={greenEnvelope}
              width="44px"
              height="44px"
              alt="email"
            />
            <Text>adwokat@kancelaria-prokop-majchrzak.com</Text>
          </Row>
          <Row>
            <SVGIcon src={greenPhone} width="44px" height="44px" alt="phone" />
            <Text>
              {intl.formatMessage({ id: "address5" })} +48 606-276-584
            </Text>
          </Row>
        </address>
      </ContactWrapper>
    </Element>
  );
};

export default Contact;
