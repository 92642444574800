import React from "react";
import Styles from "./styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";

interface IInfo {
  Content: any;
  reverted?: boolean;
  question?: boolean;
}

const { DoubleWrapper, QuestionsWrapper, QuestionText } = Styles;

const Info = ({ Content, reverted, question }: IInfo) => {
  const intl = useIntl();

  return (
    <>
      <DoubleWrapper reverted={reverted}>
        <Content />
      </DoubleWrapper>
      {question && (
        <QuestionsWrapper>
          <QuestionText>
            {intl.formatMessage({ id: "questions" })}{" "}
          </QuestionText>
          <QuestionText>{intl.formatMessage({ id: "order" })}</QuestionText>
        </QuestionsWrapper>
      )}
    </>
  );
};

export default Info;
